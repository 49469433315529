function Icon({ size = "20", color = "#000000" }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.33 8.49994L18 9.97994L18.66 9.22994L16.33 7.15994V7.09994H16.26L10.66 2.12994C10.47 1.95994 10.19 1.95994 10 2.12994L4.4 7.09994H4.33V7.15994L2 9.23994L2.66 9.98994L4.33 8.50994V17.1199H2.33V18.1199H9.33V17.1199H8.32V12.6099C8.32 12.3299 8.55 12.1099 8.82 12.1099H11.82C12.1 12.1099 12.32 12.3399 12.32 12.6099V17.1199H11.32V18.1199H18.32V17.1199H16.32V8.49994H16.33ZM11.83 11.0999H8.83C8 11.0999 7.33 11.7699 7.33 12.5999V17.1099H5.34V7.60994L10.33 3.16994L15.33 7.60994V17.1099H13.33V12.5999C13.33 11.7699 12.66 11.0999 11.83 11.0999ZM11.58 7.85994C11.58 8.54994 11.02 9.10994 10.33 9.10994C9.64 9.10994 9.08 8.54994 9.08 7.85994C9.08 7.16994 9.64 6.60994 10.33 6.60994C11.02 6.60994 11.58 7.16994 11.58 7.85994Z"
        fill={color}
      />
    </svg>
  );
}

export default Icon;
